import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTimelineContext } from "../contexts/timelineContext";
import { timelineData } from "./consts/timelineConstants";
import {
  CategoryButtonsProps,
  TimelineData,
  TimelineItemProps,
} from "./models/timelineModels";

const CategoryButtons: React.FC<CategoryButtonsProps> = ({
  activeCategory,
  setActiveCategory,
}) => {
  const categories = ["Work", "Projects", "Education", "Misc"];

  return (
    <div className="flex justify-center mb-8">
      {categories.map((category, index) => (
        <button
          key={category}
          onClick={() => setActiveCategory(category.toLowerCase())}
          className={`md:px-6 md:py-4 px-3 py-3 text-xl transition-all duration-500 ease-in-out
            ${
              index === 0
                ? "rounded-l-xl"
                : index === categories.length - 1
                ? "rounded-r-xl"
                : "rounded-none"
            } 
            ${
              activeCategory === category.toLowerCase()
                ? "border dark:border-white border-black bg-black dark:bg-white text-white dark:text-black"
                : "border dark:border-white border-black bg-transparent dark:bg-transparent text-black dark:text-white hover:bg-gray-200 dark:hover:bg-gray-800"
            }`}
        >
          <p className="duration-100">{category}</p>
        </button>
      ))}
    </div>
  );
};

const Timeline: React.FC = () => {
  const { activeCategory, setActiveCategory } = useTimelineContext();
  const [isVisible, setIsVisible] = useState(false);
  const timelineRef = useRef<HTMLDivElement>(null);

  const sortedTimelineData = useMemo(() => {
    const sortedData: TimelineData = {};
    Object.keys(timelineData).forEach((category) => {
      sortedData[category] = timelineData[category].sort((a, b) => {
        if (a.weight !== null && b.weight != null) {
          return b.weight - a.weight;
        } else if (a.weight !== null) {
          return -1;
        } else if (b.weight !== null) {
          return 1;
        }
        const endYearA =
          a.endYear === "Present" ? new Date().getFullYear() + 1 : a.endYear;
        const endYearB =
          b.endYear === "Present" ? new Date().getFullYear() + 1 : b.endYear;
        return Number(endYearB) - Number(endYearA);
      });
    });
    return sortedData;
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    if (timelineRef.current) {
      observer.observe(timelineRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <div
      className="max-w-3xl mx-auto p-5 font-sans relative z-10 pb-16"
      ref={timelineRef}
    >
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={isVisible ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.5 }}
        className="flex flex-col items-center"
      >
        <CategoryButtons
          activeCategory={activeCategory}
          setActiveCategory={setActiveCategory}
        />
        <div className="relative flex w-full">
          <div className="absolute left-0 top-0 bottom-0 w-0.5 bg-gray-300 dark:bg-gray-700 duration-300"></div>
          <div className="grow ml-8">
            <AnimatePresence mode="wait">
              <motion.div
                key={activeCategory}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3 }}
              >
                {sortedTimelineData[activeCategory].map((item, index) => (
                  <TimelineItem
                    key={index}
                    item={item}
                    isProject={activeCategory === "projects"}
                  />
                ))}
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

const TimelineItem: React.FC<TimelineItemProps> = ({
  item,
  isProject = false,
}) => {
  const [imageSrc, setImageSrc] = useState<string | null>(null);

  useEffect(() => {
    if (isProject && item.imageSrc) {
      import(`../images/icons/${item.imageSrc}`)
        .then((image) => {
          setImageSrc(image.default);
        })
        .catch((err) => console.error("Error loading image:", err));
    }
  }, [isProject, item.imageSrc]);

  const parseBoldText = (text: string) => {
    const parts = text.split(/(\*\*.*?\*\*)/g);
    return parts.map((part, index) => {
      if (part.startsWith("**") && part.endsWith("**")) {
        return <strong key={index}>{part.slice(2, -2)}</strong>;
      }
      return part;
    });
  };

  return (
    <div className="relative mb-8 p-2">
      <div className="flex items-center">
        {isProject && imageSrc && (
          <motion.img
            src={imageSrc}
            alt={`${item.title} icon`}
            className="w-12 h-12 mr-4 rounded-lg"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          />
        )}
        <div className="flex flex-col justify-center">
          <h3 className="text-xl text-gray-800 dark:text-gray-200 mb-1">
            {item.title}
          </h3>
          <h4 className="text-md text-gray-600 dark:text-gray-400">
            {parseBoldText(item.subtitle)}
            <span className="text-sm text-gray-500">
              {" | "}
              {item.startYear === item.endYear
                ? item.startYear
                : `${item.startYear} - ${item.endYear}`}
            </span>
          </h4>
        </div>
      </div>
      <div>
        <div>
          {item.links.length > 0 && (
            <div className="mt-2">
              {item.links.map((link, index) => (
                <React.Fragment key={index}>
                  {index > 0 && <span className="text-gray-500 mx-2">|</span>}
                  <a
                    href={link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-gray-800 dark:text-gray-200 font-semibold hover:underline"
                  >
                    {link.text}
                  </a>
                </React.Fragment>
              ))}
            </div>
          )}
          <div className="mt-2 space-y-2">
            {item.bulletPoints.map((point, index) => (
              <div key={index} className="flex items-center ml-3">
                <span className="text-gray-500 mr-2 text-xs">►</span>
                <span className="text-gray-700 dark:text-gray-300">
                  {parseBoldText(point)}
                </span>
              </div>
            ))}
          </div>
          {item.technologiesUsed.length > 0 && (
            <div className="flex flex-wrap gap-2 mt-3">
              {item.technologiesUsed.map((tech, index) => (
                <span
                  key={index}
                  className="inline-block duration-300 bg-gray-200 dark:bg-gray-800 rounded-full px-3 py-1 text-xs font-semibold text-gray-800 dark:text-gray-300"
                >
                  {tech}
                </span>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Timeline;
