import React from 'react';

const baseInputStyles = "w-full p-2 border rounded-lg bg-transparent dark:text-white text-black dark:border-white border-black";

interface InputFieldProps {
  name: string;
  type?: string;
  value: string;
  onChange: (value: string) => void;
  required?: boolean;
}

export const InputField: React.FC<InputFieldProps> = ({ name, type = 'text', value, onChange, required }) => (
  <input
    type={type}
    name={name}
    placeholder={name.charAt(0).toUpperCase() + name.slice(1)}
    value={value}
    onChange={(e) => onChange(e.target.value)}
    required={required}
    className={baseInputStyles}
  />
);

interface TextAreaFieldProps {
  name: string;
  value: string;
  onChange: (value: string) => void;
  required?: boolean;
}

export const TextAreaField: React.FC<TextAreaFieldProps> = ({ name, value, onChange, required }) => (
  <textarea
    name={name}
    placeholder={name.charAt(0).toUpperCase() + name.slice(1)}
    value={value}
    onChange={(e) => onChange(e.target.value)}
    required={required}
    className={`${baseInputStyles} h-32`}
  />
);