export interface TimelineItemProps {
  item: TimelineItemModel;
  isProject: boolean;
}

export interface CategoryButtonsProps {
  activeCategory: string;
  setActiveCategory: (category: string) => void;
}

export interface TimelineData {
  [key: string]: TimelineItemModel[];
}

export interface Link {
  url: string;
  text: string;
}

export class TimelineItemModel {
  title: string;
  subtitle: string;
  startYear: number;
  endYear: number | string;
  links: Link[];
  bulletPoints: string[];
  technologiesUsed: string[];
  imageSrc: string | null;
  weight: number | null;
  constructor(
    title: string,
    subtitle: string,
    startYear: number,
    endYear: number | string,
    links: (string | Link)[] = [],
    bulletPoints: string[] = [],
    technologiesUsed: string[] = [],
    imageSrc: string | null = null,
    weight: number | null = null
  ) {
    this.title = title;
    this.subtitle = subtitle;
    this.startYear = startYear;
    this.endYear = endYear || "Present";
    this.links = links.map((link) =>
      typeof link === "string" ? { url: link, text: "Link" } : link
    );
    this.bulletPoints = bulletPoints;
    this.technologiesUsed = technologiesUsed;
    this.imageSrc = imageSrc;
    this.weight = weight;
  }
}
