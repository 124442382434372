import emailjs from "@emailjs/browser";
import { motion, useInView } from "framer-motion";
import React, { FormEvent, useRef, useState } from "react";
import { useContact } from "../contexts/contactContext";
import { InputField, TextAreaField } from "./FormFields";

const Contact: React.FC = () => {
  const {
    name,
    setName,
    email,
    setEmail,
    message,
    setMessage,
    title,
    setTitle,
  } = useContact();
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, amount: 0.2 });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      await emailjs.send(
        "service_win92lk",
        "template_u6cx73w",
        {
          to_name: "Tomer",
          from_name: name,
          from_email: email,
          subject: title,
          message: message,
        },
        "M_GdCFDEmL-dmTZR2"
      );
      await emailjs.send(
        "service_win92lk",
        "template_jgcw8ba",
        {
          to_name: "Tomer",
          from_name: name,
          from_email: email,
        },
        "M_GdCFDEmL-dmTZR2"
      );

      setIsSubmitted(true);
    } catch (error) {
      console.error("Failed to send email:", error);
      // Handle error (e.g., show error message)
    } finally {
      setIsSubmitting(false);
    }
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
  };

  const tickVariants = {
    hidden: { pathLength: 0, opacity: 0 },
    visible: {
      pathLength: 1,
      opacity: 1,
      transition: { duration: 0.5, ease: "easeInOut" },
    },
  };

  return (
    <div ref={ref} className="container mx-auto px-4 py-8 h-[70vh]">
      <motion.h1
        initial={{ opacity: 0, y: -20 }}
        animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: -20 }}
        transition={{ duration: 0.5 }}
        className="text-5xl text-center mb-12 dark:text-white text-black"
      >
        Contact Me
      </motion.h1>
      {!isSubmitted ? (
        <motion.form
          variants={containerVariants}
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
          onSubmit={handleSubmit}
          className="max-w-md mx-auto space-y-4"
        >
          <motion.div variants={itemVariants}>
            <InputField name="name" value={name} onChange={setName} required />
          </motion.div>
          <motion.div variants={itemVariants}>
            <InputField
              name="email"
              type="email"
              value={email}
              onChange={setEmail}
              required
            />
          </motion.div>
          <motion.div variants={itemVariants}>
            <InputField
              name="title"
              value={title}
              onChange={setTitle}
              required
            />
          </motion.div>
          <motion.div variants={itemVariants}>
            <TextAreaField
              name="message"
              value={message}
              onChange={setMessage}
              required
            />
          </motion.div>
          <motion.button
            variants={itemVariants}
            type="submit"
            disabled={isSubmitting}
            className="w-full p-2 bg-black dark:bg-white text-white dark:text-black rounded-lg hover:bg-gray-800 dark:hover:bg-gray-200 transition-colors duration-300 disabled:opacity-50"
          >
            {isSubmitting ? "Sending..." : "Send Email"}
          </motion.button>
        </motion.form>
      ) : (
        <motion.div
          initial="hidden"
          animate="visible"
          className="flex flex-col items-center justify-center space-y-4"
        >
          <motion.svg
            width="100"
            height="100"
            viewBox="0 0 100 100"
            className="text-black dark:text-white"
          >
            <motion.path
              d="M20,50 L40,70 L80,30"
              fill="none"
              stroke="currentColor"
              strokeWidth="8"
              variants={tickVariants}
            />
          </motion.svg>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5 }}
            className="text-2xl font-bold text-center dark:text-white text-black"
          >
            Talk to you soon!
          </motion.p>
        </motion.div>
      )}
    </div>
  );
};

export default Contact;
