import React, { useCallback } from "react";
import Particles from "react-tsparticles";
import { Engine, ISourceOptions } from "tsparticles-engine";
import { loadLinksPreset } from "tsparticles-preset-links";
import { useTheme } from "../hooks/useTheme";

const ParticleBackground: React.FC = () => {
  const { theme } = useTheme();

  const particlesInit = useCallback(async (engine: Engine) => {
    await loadLinksPreset(engine);
  }, []);

  const particlesOptions: ISourceOptions = {
    preset: "links",
    background: {
      opacity: 0,
    },
    particles: {
      number: {
        value: 40,
        density: {
          enable: true,
          area: 800,
        },
      },
      color: {
        value: theme === "dark" ? "#fff" : "#000",
      },
      links: {
        color: theme === "dark" ? "#fff" : "#000",
        opacity: 0.5,
        distance: 150,
        enable: true,
        triangles: {
          enable: false,
        },
      },
      move: {
        speed: 0.5,
        outModes: {
          default: "bounce",
        },
      },
    },
    interactivity: {
      events: {
        onHover: { 
          enable: true, 
          mode: "grab" 
        },
        onClick: {
          enable: true,
          mode: "push"
        },
      },
      modes: {
        grab: {
          distance: 140,
          links: { opacity: 1 },
        },
        push: {
          quantity: 4,
        },
      },
    },
  };

  return (
    <div className="absolute inset-0 z-0">
      <div
        className={`absolute inset-0 transition-colors duration-500 ease-in-out bg-gray-100 dark:bg-black`}
      />
      <Particles
        className="absolute inset-0"
        init={particlesInit}
        options={particlesOptions}
        style={{ zIndex: -1 }}
      />
    </div>
  );
};

export default ParticleBackground;
