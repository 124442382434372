import { motion, useInView } from "framer-motion";
import React, { useRef } from "react";
import "./SatisfiedCustomers.css";

interface Customer {
    name: string;
    logo: string;
}

const customers: Customer[] = [
    { name: "Matrix", logo: "matrix.png" },
    { name: "Ministry of Defense", logo: "ministry-of-defense.svg" },
    { name: "BGU", logo: "bgu.png" },
    { name: "Mixtiles", logo: "mixtiles.png" },
    { name: "Mada", logo: "mada.svg" },
    { name: "Guy Koren Schools", logo: "guy-koren.png" },
];

const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { 
        opacity: 1, 
        y: 0, 
        transition: { duration: 0.8, staggerChildren: 0.2 } 
    }
};

const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
};

const SatisfiedCustomers: React.FC = () => {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true, amount: 0.3 });

    return (
        <motion.div 
            ref={ref}
            className="pb-16"
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
            variants={containerVariants}
        >
            <motion.h1
                className="text-5xl text-center mb-6 dark:text-white text-black"
                variants={itemVariants}
            >
                Satisfied Customers
            </motion.h1>

            <motion.div 
                className="mt-16 max-w-3xl mx-auto relative overflow-hidden"
                variants={itemVariants}
            >
                <GradientOverlay position="left" />
                <Banner images={customers.map(customer => ({ 
                    name: customer.name, 
                    logo: require(`../images/companies/${customer.logo}`) 
                }))} />
                <GradientOverlay position="right" />
            </motion.div>
        </motion.div>
    );
};

interface BannerProps {
    images: { name: string; logo: string }[];
    speed?: number;
}

const Banner: React.FC<BannerProps> = ({ images, speed = 40000 }) => (
    <div className="h-24">
        <div className="wrapper">
            {[...Array(3)].map((_, index) => (
                <section
                    key={index}
                    className="section"
                    style={{ "--speed": `${speed}ms` } as React.CSSProperties}
                >
                    {images.map(({ name, logo }) => (
                        <div key={name}>
                            <img src={logo} alt={name} className="mx-20 w-24 h-24 object-contain brightness-0 dark:invert" />
                        </div>
                    ))}
                </section>
            ))}
        </div>
    </div>
);

interface GradientOverlayProps {
    position: "left" | "right";
}

const GradientOverlay: React.FC<GradientOverlayProps> = ({ position }) => (
    <div className={`absolute inset-y-0 ${position}-0 w-32 bg-gradient-to-${position === "left" ? "r" : "l"} from-gray-100 to-transparent z-10 dark:from-black`} />
);

export default SatisfiedCustomers;
