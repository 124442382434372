import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/20/solid";
import { AnimatePresence, motion, useInView } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { IconType } from "react-icons";
import { AiOutlineOpenAI } from "react-icons/ai";
import { DiAndroid, DiCodeBadge } from "react-icons/di";
import {
  FaAws,
  FaCloud,
  FaDatabase,
  FaFigma,
  FaLinux,
  FaPython,
  FaReact,
} from "react-icons/fa";
import { FaGolang } from "react-icons/fa6";
import { GrVulnerability } from "react-icons/gr";
import { IoLogoJavascript } from "react-icons/io5";
import { RiFlutterFill } from "react-icons/ri";
import { SiAdobe, SiApple, SiKotlin } from "react-icons/si";
import { Tooltip } from "react-tooltip";
import { useContact } from "../contexts/contactContext";
import useSmoothScroll from "../hooks/useSmoothScroll";

const minWidthForDesktop = 768;

interface Service {
  title: string;
  description: string;
  icons: IconType[];
}

const services: Service[] = [
  {
    title: "Mobile App Development",
    description:
      "Experienced in creating successful mobile apps with millions of users. Let's build your perfect app together.",
    icons: [RiFlutterFill, SiKotlin, DiAndroid, SiApple],
  },
  {
    title: "Frontend Design/Development",
    description:
      "Crafting responsive, user-friendly interfaces with 10+ years of UI/UX expertise. Using modern web technologies.",
    icons: [RiFlutterFill, FaReact, SiAdobe, FaFigma, IoLogoJavascript],
  },
  {
    title: "Backend Development",
    description:
      "Designing scalable and efficient server-side solutions to power your applications.",
    icons: [FaPython, FaGolang, AiOutlineOpenAI, FaDatabase, FaAws],
  },
  {
    title: "Cybersecurity Consultant",
    description:
      "Consultation, research, and development in network security, vulnerability R&D, and secure architecture design.",
    icons: [FaCloud, DiCodeBadge, GrVulnerability, FaLinux],
  },
];

const iconNames: { [key: string]: string } = {
  DiGo: "Go",
  FaGolang: "Golang",
  FaPython: "Python",
  FaLinux: "Linux",
  FaCloud: "Network Security",
  RiFlutterFill: "Flutter/Dart",
  SiKotlin: "Kotlin + Compose",
  SiJetpackcompose: "Jetpack Compose",
  SiAndroidstudio: "Android Studio",
  FaReact: "ReactJS",
  RiTailwindCssFill: "Tailwind CSS",
  IoLogoJavascript: "JavaScript/TypeScript",
  FaNodeJs: "Node.js",
  FaDatabase: "SQL/Redis",
  GrVulnerability: "Vulnerability Research",
  DiCodeBadge: "Cyber-Oriented Development",
  FaAws: "AWS",
  AiOutlineOpenAI: "AI Integration",
  SiAdobe: "Adobe Suite",
  SiApple: "iOS",
  DiAndroid: "Android",
  FaFigma: "Figma",
};

const IconsDisplay: React.FC<{ icons: IconType[] }> = ({ icons }) => (
  <div className="flex mt-8 space-x-4 justify-center">
    {icons.map((Icon, index) => (
      <div key={index} className="relative">
        <Icon
          data-tooltip-id={`icon-tooltip-${index}`}
          data-tooltip-content={iconNames[Icon.name]}
          className="w-7 h-7 hover:scale-125 transition-all duration-100 ease-in-out"
        />
        <Tooltip id={`icon-tooltip-${index}`} />
      </div>
    ))}
  </div>
);

const CardContent: React.FC<Service & { isDesktop: boolean }> = ({
  title,
  description,
  icons,
  isDesktop,
}) => (
  <div className="px-6 py-2 flex flex-col h-full duration-100 transition-all ease-in-out">
    {isDesktop ? (
      <>
        <h2 className="text-2xl font-semibold mb-4 truncate">{title}</h2>
        <p className="flex-grow">{description}</p>
        <IconsDisplay icons={icons} />
      </>
    ) : (
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="flex justify-between w-full py-4 text-xl font-medium text-left focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
              <span>{title}</span>
              <ChevronUpIcon
                className={`${
                  !open ? "transform rotate-180" : ""
                } w-5 h-5 text-gray-500 transition-transform duration-200`}
              />
            </Disclosure.Button>
            <AnimatePresence>
              {open && (
                <Disclosure.Panel static>
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.2 }}
                  >
                    <div className="text-gray-800 dark:text-gray-200">
                      {description}
                      <IconsDisplay icons={icons} />
                    </div>
                  </motion.div>
                </Disclosure.Panel>
              )}
            </AnimatePresence>
          </>
        )}
      </Disclosure>
    )}
  </div>
);

const TechStack: React.FC = () => {
  const { setTitle, setMessage } = useContact();
  const scrollToElement = useSmoothScroll();

  const handleServiceClick = (serviceTitle: string) => {
    setTitle(`Inquiry: ${serviceTitle} services`);
    setMessage(
      `Hi Tomer,\nI am interested in your services regarding ${serviceTitle}`
    );
    scrollToElement("contact");
  };

  const [isDesktop, setIsDesktop] = useState(
    window.innerWidth >= minWidthForDesktop
  );

  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, amount: 0.1 });

  useEffect(() => {
    const handleResize = () =>
      setIsDesktop(window.innerWidth >= minWidthForDesktop);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        ease: "easeOut",
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: "easeOut",
      },
    },
  };

  return (
    <motion.div
      ref={ref}
      className="container mx-auto px-4 py-8 pb-24"
      animate={isInView ? "visible" : "hidden"}
      variants={containerVariants}
    >
      <motion.h1
        className="text-5xl text-center mb-6 dark:text-white text-black"
        variants={itemVariants}
      >
        How I can help you
      </motion.h1>
      <motion.p
        className="text-xl text-center mb-12 dark:text-white text-black"
        variants={itemVariants}
      >
        Ready to take your project to the next level? I'm available for
        freelance work.
        <a
          href="#contact"
          onClick={(e) => {
            e.preventDefault();
            scrollToElement("contact");
          }}
          className="text-blue-500 hover:text-blue-600 cursor-pointer"
        >
          &nbsp;Get in touch
        </a>
      </motion.p>
      <motion.div
        className={`xl:mx-64 grid ${"md:px-0 px-8 md:grid-cols-2 grid-cols-1 gap-6"}`}
        variants={containerVariants}
      >
        {services.map((service, index) => (
          <motion.div
            key={index}
            className={`${
              isDesktop ? "hover:scale-110" : ""
            } transition-all duration-300 ease-in-out py-8 border dark:border-white border-black dark:text-white text-black rounded-lg shadow-md overflow-hidden cursor-pointer`}
            onClick={() => handleServiceClick(service.title)}
            variants={itemVariants}
          >
            <CardContent {...service} isDesktop={isDesktop} />
          </motion.div>
        ))}
      </motion.div>
    </motion.div>
  );
};

export default TechStack;
