import { useCallback } from 'react';

const useSmoothScroll = () => {
  const scrollToElement = useCallback((elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return scrollToElement;
};

export default useSmoothScroll;