import React, { useEffect, useRef, useState } from "react";
import { IconType } from "react-icons";
import {
  FaBriefcase,
  FaCode,
  FaEnvelope,
  FaGithub,
  FaLinkedin,
  FaMoon,
  FaSpotify,
  FaSun,
  FaTwitter,
  FaUser,
} from "react-icons/fa";
import { useTimelineContext } from "../contexts/timelineContext";
import useScrollVisibility from "../hooks/useScrollVisibility";
import useSmoothScroll from "../hooks/useSmoothScroll";
import { useTheme } from "../hooks/useTheme";
import "./navbar.css";

const MOUNT_DELAY = 4400;

interface SocialPlatform {
  name: string;
  url: string;
  icon: IconType;
}

interface NavigateItem {
  name: string;
  id: string;
  icon: IconType;
  category?: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}

const socialPlatforms: SocialPlatform[] = [
  { name: "GitHub", url: "https://github.com/rosenpin", icon: FaGithub },
  {
    name: "LinkedIn",
    url: "https://www.linkedin.com/in/tomer-r/",
    icon: FaLinkedin,
  },
  { name: "Twitter", url: "https://twitter.com/rosenpin", icon: FaTwitter },
  {
    name: "Spotify",
    url: "https://open.spotify.com/artist/1SRTcccDPNNL7naJZ7eC8Z?si=id0x3s4ATCauaOnGwKNUXw",
    icon: FaSpotify,
  },
];

const navigateItems: NavigateItem[] = [
  { name: "Profile", id: "home", icon: FaUser },
  { name: "Experience", id: "experience", icon: FaBriefcase, category: "work" },
  { name: "Projects", id: "experience", icon: FaCode, category: "projects" },
  { name: "Contact", id: "contact", icon: FaEnvelope },
];

const Navbar: React.FC = () => {
  const isVisible = useScrollVisibility();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setMounted(true), MOUNT_DELAY);
    return () => clearTimeout(timer);
  }, []);

  return (
    <nav
      className={`ToolBar mt-2 navbar-transition ${
        mounted ? "" : "opacity-0"
      } ${isVisible ? "translate-y-0" : "-translate-y-full opacity-0"}`}
      id="navbar"
    >
      <ThemeToggleButton />
      <div className="flex space-x-4 items-center">
        <NavigateDropdown />
        <SocialDropdown />
      </div>
    </nav>
  );
};

const ThemeToggleButton: React.FC = () => {
  const { theme, toggleTheme } = useTheme();
  const isLight = theme === "light";

  return (
    <button onClick={toggleTheme} className="Navbar-Button absolute left-4">
      {isLight ? (
        <FaMoon
          className={`transition-opacity duration-300 ${
            isLight ? "opacity-100" : "opacity-0"
          }`}
        />
      ) : (
        <FaSun
          className={`transition-opacity duration-300 ${
            isLight ? "opacity-0" : "opacity-100"
          }`}
        />
      )}
    </button>
  );
};

interface GenericDropdownProps {
  buttonText: string;
  icon?: IconType;
  items: (SocialPlatform | NavigateItem)[];
  openInNewTab: boolean;
}

const GenericDropdown: React.FC<GenericDropdownProps> = ({
  buttonText,
  icon: Icon,
  items,
  openInNewTab,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleMouseEnter = () => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => setIsOpen(false), 100);
  };

  return (
    <div className="relative" onMouseLeave={handleMouseLeave}>
      <button className="Navbar-Button" onMouseEnter={handleMouseEnter}>
        <span className="flex flex-row items-center">
          {Icon && <Icon className="mr-2" />}
          {buttonText}
        </span>
      </button>
      <div className=" left-1/2 transform -translate-x-1/2">
        <DropdownMenu
          isOpen={isOpen}
          items={items}
          openInNewTab={openInNewTab}
          onMouseEnter={handleMouseEnter}
        />
      </div>
    </div>
  );
};

interface DropdownMenuProps {
  isOpen: boolean;
  items: (SocialPlatform | NavigateItem)[];
  openInNewTab: boolean;
  onMouseEnter: () => void;
}

function DropdownMenu({
  isOpen,
  items,
  openInNewTab,
  onMouseEnter,
}: DropdownMenuProps) {
  return (
    <div
      className={`absolute shadow-lg bg-gray-200 dark:bg-gray-700 text-white rounded-xl shadow-lg mt-1 transition-all duration-300 ease-in-out ${
        isOpen
          ? "opacity-100 translate-y-0"
          : "opacity-0 -translate-y-2 pointer-events-none"
      }`}
      onMouseEnter={onMouseEnter}
    >
      {items.map((item) => (
        <DropdownItem key={item.name} item={item} openInNewTab={openInNewTab} />
      ))}
    </div>
  );
}

interface DropdownItemProps {
  item: SocialPlatform | NavigateItem;
  openInNewTab: boolean;
}

const DropdownItem: React.FC<DropdownItemProps> = ({ item, openInNewTab }) => {
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if ("onClick" in item && item.onClick) {
      event.preventDefault();
      item.onClick(event);
    }
  };

  return (
    <a
      href={"url" in item ? item.url : `#${item.id}`}
      className="flex dark:text-white text-black items-center px-6 py-2 hover:bg-gray-300 hover:dark:bg-gray-600 rounded-xl transition-all duration-300 hover:py-4"
      target={openInNewTab ? "_blank" : "_self"}
      rel="noopener noreferrer"
      onClick={handleClick}
    >
      {item.icon && <item.icon className="mr-2" />}
      {item.name}
    </a>
  );
};

function NavigateDropdown() {
  const scrollToElement = useSmoothScroll();
  const { setActiveCategory } = useTimelineContext();

  const handleItemClick = (
    event: React.MouseEvent<HTMLAnchorElement>,
    item: NavigateItem
  ) => {
    event.preventDefault();
    scrollToElement(item.id);
    if (item.category) {
      setActiveCategory(item.category);
    }
  };

  return (
    <GenericDropdown
      buttonText="Navigate"
      items={navigateItems.map((item) => ({
        ...item,
        onClick: (event: React.MouseEvent<HTMLAnchorElement>) =>
          handleItemClick(event, item),
      }))}
      openInNewTab={false}
    />
  );
}

function SocialDropdown() {
  return (
    <GenericDropdown
      buttonText="Social"
      items={socialPlatforms}
      openInNewTab={true}
    />
  );
}

export default Navbar;
