import React, { createContext, useContext, useState } from "react";

interface TimelineContextType {
  activeCategory: string;
  setActiveCategory: (category: string) => void;
}

const TimelineContext = createContext<TimelineContextType | undefined>(undefined);

export const TimelineProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [activeCategory, setActiveCategory] = useState<string>("work");

  return (
    <TimelineContext.Provider value={{ activeCategory, setActiveCategory }}>
      {children}
    </TimelineContext.Provider>
  );
};

export const useTimelineContext = () => {
  const context = useContext(TimelineContext);
  if (context === undefined) {
    throw new Error("useTimelineContext must be used within a TimelineProvider");
  }
  return context;
};