import React from "react";
import { FaEnvelope, FaGithub, FaLinkedin } from "react-icons/fa";
import { IoLocationSharp } from "react-icons/io5";
import { Tooltip } from "react-tooltip";

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();

  const handleEmailClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    const email = "mail@tomerrosenfeld.com";
    const mailtoUrl = `mailto:${email}`;

    // Try to open the mailto link
    window.location.href = mailtoUrl;

    setTimeout(() => {
      if (document.hasFocus()) {
        // If the window is still in focus, mailto likely failed
        navigator.clipboard.writeText(email).then(() => {
          alert("Email address copied to clipboard!");
        }).catch((err) => {
          console.error("Failed to copy email: ", err);
        });
      }
    }, 100);
  };

  const socialLinks = [
    {
      href: "mailto:mail@tomerrosenfeld.com",
      icon: FaEnvelope,
      tooltipId: "email-tooltip",
      tooltipContent: "mail@tomerrosenfeld.com",
      onClick: handleEmailClick,
    },
    {
      href: "https://github.com/rosenpin",
      icon: FaGithub,
    },
    {
      href: "https://www.linkedin.com/in/tomer-r/",
      icon: FaLinkedin,
    },
  ];

  return (
    <footer className="bg-gray-100 dark:bg-gray-900 text-black dark:text-white py-8">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
          <div className="w-full md:w-1/3 flex justify-center md:justify-start">
            <p className="flex items-center">
              <IoLocationSharp className="mr-2 text-xl" />
              Tel Aviv, Israel
            </p>
          </div>
          <div className="w-full md:w-1/3 flex justify-center space-x-4">
            {socialLinks.map((link, index) => (
              <React.Fragment key={index}>
                <a
                  href={link.href}
                  target={link.href.startsWith("http") ? "_blank" : undefined}
                  rel={link.href.startsWith("http") ? "noopener noreferrer" : undefined}
                  className="hover:text-gray-500 dark:hover:text-gray-400 transition-colors duration-300"
                  data-tooltip-id={link.tooltipId}
                  data-tooltip-content={link.tooltipContent}
                  onClick={link.onClick}
                >
                  <link.icon className="text-xl" />
                </a>
                {link.tooltipId && <Tooltip id={link.tooltipId} />}
              </React.Fragment>
            ))}
          </div>
          <div className="w-full md:w-1/3 flex justify-center md:justify-end">
            <p>&copy; {currentYear} Tomer Rosenfeld. All rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
