import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { HiChevronDoubleDown } from "react-icons/hi";
import Typewriter from "typewriter-effect";
import { useTimelineContext } from "../contexts/timelineContext";
import useScrollVisibility from "../hooks/useScrollVisibility";
import useSmoothScroll from "../hooks/useSmoothScroll";
import { useTheme } from "../hooks/useTheme";

interface HeaderProps {
  onAnimationsComplete: () => void;
}

function Header({ onAnimationsComplete }: HeaderProps) {
  const { theme, toggleTheme } = useTheme();

  return (
    <div className="relative z-10">
      <HeaderContent
        theme={theme}
        toggleTheme={toggleTheme}
        onAnimationsComplete={onAnimationsComplete}
      />
    </div>
  );
}

interface HeaderContentProps {
  theme: string;
  toggleTheme: () => void;
  onAnimationsComplete: () => void;
}

function HeaderContent({ onAnimationsComplete }: HeaderContentProps) {
  const [showButtons, setShowButtons] = useState<boolean>(false);
  const [showArrow, setShowArrow] = useState<boolean>(false);
  const scrollToElement = useSmoothScroll();
  const { setActiveCategory } = useTimelineContext();
  const isVisible = useScrollVisibility();
  const [, setAnimationsComplete] = useState<boolean>(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowArrow(true);
      setAnimationsComplete(true);
      onAnimationsComplete(); // Call the prop function when animations are complete
    }, 4700);

    return () => clearTimeout(timer);
  }, [onAnimationsComplete]);

  const handleViewProjects = () => {
    scrollToElement("experience");
    setActiveCategory("projects");
  };

  const handleContactMe = () => {
    scrollToElement("contact");
  };

  return (
    <div className="duration-300 relative z-10 flex flex-col items-center pt-56 h-screen">
      <motion.img
        src={require("../images/profile.jpg")}
        alt="Profile"
        className="shadow-xl w-48 h-48 rounded-full mb-6 object-cover object-center"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 0.2 }}
      />
      <div className="dark:text-white text-black h-12 text-center text-lg">
        <Typewriter
          onInit={(typeWriter) => {
            typeWriter
              .pauseFor(1100)
              .typeString("Hi,")
              .pauseFor(300)
              .typeString(" I'm <b>Tomer</b>.")
              .pauseFor(400)
              .typeString(" A Software-Man who gets things <b>done</b>.")
              .callFunction(() => {
                setShowButtons(true);
                setAnimationsComplete(true); // Set animations complete after typing
              })
              .start();
          }}
          options={{
            autoStart: true,
            delay: 40,
          }}
        />
      </div>
      {showButtons && (
        <motion.span
          className="flex flex-row sm:mt-2 mt-8"
          initial="hidden"
          animate="visible"
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1, transition: { staggerChildren: 0.1 } },
          }}
        >
          <motion.button
            onClick={handleViewProjects}
            variants={{
              hidden: { opacity: 0, y: 10, x: -10 },
              visible: {
                opacity: 1,
                y: 0,
                x: 0,
                transition: { type: "spring", stiffness: 100 },
              },
            }}
            className="bg-black dark:bg-white hover:scale-105 duration-300 rounded-lg text-white dark:text-black font-bold py-2 px-4 rounded mr-2"
          >
            View Projects
          </motion.button>
          <motion.button
            onClick={handleContactMe}
            variants={{
              hidden: { opacity: 0, y: 10, x: 10 },
              visible: {
                opacity: 1,
                y: 0,
                x: 0,
                transition: { type: "spring", stiffness: 100 },
              },
            }}
            className="bg-gray-200 dark:bg-gray-800 hover:scale-105 duration-300 rounded-lg text-black dark:text-white font-bold py-2 px-4 rounded ml-2"
          >
            Contact Me
          </motion.button>
        </motion.span>
      )}

      <AnimatePresence>
        {isVisible && showArrow && (
          <motion.div
            className="absolute bottom-10 left-0 right-0 flex justify-center"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 0.8, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            transition={{ duration: 0.3 }}
          >
            <motion.div
              initial={{ opacity: 0.2, y: -20 }}
              animate={{ opacity: 0.8, y: 5 }}
              whileHover={{ scale: 1.1 }}
              transition={{
                repeat: Infinity,
                repeatType: "reverse",
                duration: 1,
              }}
              onClick={() => scrollToElement("experience")}
            >
              <HiChevronDoubleDown className="h-16 w-16 text-black dark:text-white cursor-pointer" />
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default Header;
